<script lang="ts" setup>
import type {
  CmsElementProductSlider,
  SliderElementConfig,
} from "@shopware-pwa/composables-next";
import { useCmsElementConfig } from "#imports";
import type { ComputedRef } from "vue";
import { computed, ref } from "vue";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import type { VSlideGroup } from "vuetify/components";

const props = defineProps<{
  content: CmsElementProductSlider;
}>();
const { getConfigValue } = useCmsElementConfig(props.content);

const products = computed(() => props.content?.data?.products ?? []);
const config: ComputedRef<SliderElementConfig> = computed(() => ({
  minHeight: {
    value: "300px",
    source: "static",
  },
  verticalAlign: {
    source: "static",
    value: getConfigValue("verticalAlign") || "",
  },
  displayMode: {
    value: "contain",
    source: "static",
  },
  navigationDots: {
    value: "",
    source: "static",
  },
  navigationArrows: {
    value: getConfigValue("navigation") ? "outside" : "",
    source: "static",
  },
}));

const title = computed(() => getConfigValue("title"));
const border = computed(() => getConfigValue("border"));

const slideGroup: VSlideGroup = ref(null);

const prev = () => {
  slideGroup.value?.scrollTo("prev");
};

const next = () => {
  slideGroup.value?.scrollTo("next");
  // todo: how to check if has Next to disable next btn
};
</script>
<template>
  <v-container class="my-16">
    <v-row>
      <v-col>
        <div v-if="title" class="text-h2">
          {{ title }}
        </div>
      </v-col>

      <v-col cols="auto" class="d-none d-md-block">
        <v-btn
          :disabled="slideGroup?.scrollOffset == 0"
          :icon="mdiArrowLeft"
          @click="prev"
        />
      </v-col>

      <v-col cols="auto" class="d-none d-md-block">
        <v-btn :icon="mdiArrowRight" @click="next" />
      </v-col>
    </v-row>
  </v-container>

  <div :class="{ 'py-5 border border-secondary-300': border }">
    <v-slide-group ref="slideGroup" :show-arrows="false">
      <template #next></template>
      <template #prev></template>

      <template #default>
        <v-slide-group-item v-for="product of products" :key="product.id">
          <SwProductCard
            :display-mode="getConfigValue('displayMode')"
            :layout-type="getConfigValue('boxLayout')"
            :product="product"
            class="ma-4"
            width="384"
          />
        </v-slide-group-item>
      </template>
    </v-slide-group>
  </div>

  <v-container class="d-md-none">
    <v-row>
      <v-col cols="auto">
        <v-btn
          :disabled="slideGroup?.scrollOffset == 0"
          :icon="mdiArrowLeft"
          @click="prev"
        />
      </v-col>

      <v-col cols="auto">
        <v-btn :icon="mdiArrowRight" @click="next"></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.v-slide-group {
  flex-wrap: wrap;
}

@media screen and (min-width: 1280px) {
  :deep(.v-slide-group__content) {
    transform: translateX(calc((100vw - 1280px) / 2));
  }
}
</style>
